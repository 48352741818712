@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  /* color/primary */
  --color-primary: #c69e51;
  --color-primary-cv1: #e8d8b9;
  --color-primary-cv2: #f8f3ea;

  /* color/secondary */
  --color-secondary: #ad3f4c;

  /* color/function */
  --color-function: #08092d;
  --color-function-cv1: #54556e;
  --color-function-cv2: #e6e6ea;

  /* color/text */
  --color-text: #333;
  --color-bg: #fff;

  /* font */
  --font-size-md: 16px;
  --font-size-sm: 14px;
  --font-size-xs: 12px;

  --footer-height: 0px;
}

.radix__select__content {
  max-height: 12em !important;
  width: var(--radix-select-trigger-width) !important;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Noto Sans JP', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol';
  color: var(--color-text);
  background-color: var(--color-bg);
}

svg path:not([fill='none']):not([fill='transparent']) {
  fill: inherit;
}

.mdx h2 {
  font-size: 14px;
  font-weight: 700;
  margin-top: 14px;
  margin-bottom: 4px;
}

.mdx h3 {
  font-size: 14px;
  margin-top: 14px;
  margin-bottom: 4px;
}

.mdx p {
  font-size: 14px;
}

.mdx ul {
  margin-left: 4px;
  font-size: 14px;
  list-style-type: square;
  list-style-position: inside;
}

.mdx ol {
  margin-left: 0px;
  font-size: 14px;
  list-style-type: decimal;
  list-style-position: inside;
}

.mdx ol ol {
  margin-left: 14px;
  font-size: 14px;
  list-style-type: decimal;
  list-style-position: inside;
}

.radio-label {
  display: block;
}

.gray-radio {
  display: none;
}

.radio-text:before {
  content: '';
  display: inline-block;
  width: 1em;
  height: 1em;
  margin-right: 6px;
  margin-bottom: -2px;
  border-width: 2px;
  border-style: solid;
  border-radius: 50%;
  padding: 0px;
  background-clip: content-box;
}

.gray-radio:not(:checked) + .radio-text:before {
  background-color: #d9d9d9;
  border-color: #d9d9d9;
}

.gray-radio:checked + .radio-text:before {
  border-color: #d9d9d9;
  background-color: #2e3a59;
}

.gray-outline {
  border: 1px solid #cccccc;
}

.animate-dots {
  animation: dots 2s linear infinite;
}

@keyframes dots {
  0% {
    content: '.';
  }
  40% {
    content: '..';
  }
  60% {
    content: '...';
  }
  100% {
    content: '';
  }
}

.swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: var(--color-primary-cv2);
}

.swiper-button-prev,
.swiper-button-next {
  --swiper-navigation-size: 28px;
  --swiper-navigation-color: var(--color-primary-cv2);
}

a.autoliner-link {
  color: #777;
  text-decoration: none;
}
