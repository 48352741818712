.react-datepicker {
  width: 100%;
  background-color: #faf6ee;
  border: none;
}
.react-datepicker__header {
  /* background-color: red; */
  background-color: #faf6ee;
  border-bottom: none;
}
.react-datepicker__month-container {
  float: none;
}
.react-datepicker__day {
  width: 34px;
  height: 34px;
}
.react-datepicker__day-name {
  width: 34px;
}
/* .react-datepicker__day--today, */
.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected {
  background-color: #ad3f4c !important;
  border-radius: 50% !important;
  color: #fff !important;
  opacity: 0.5;
  padding-top: 3px;
}
